import { View, Text, Dimensions } from 'react-native'
import React from 'react'
import colorSchema from '../../config/colorSchema'
const { width, height } = Dimensions.get('window')

const LogoHeader = (props) => {
    return (
        <View style={{ marginLeft: 17 }}>
            <Text style={{ fontFamily: 'Gilroy-Heavy', fontSize: 24, lineHeight: 31, color:colorSchema.mainColor }}>Yemek.Club</Text>
        </View>
    )
}

export default LogoHeader