import { View, Text, SafeAreaView, Image, TouchableOpacity, ScrollView, Dimensions, StyleSheet } from 'react-native'
import React, { useState, useEffect } from 'react'
import { useFonts } from 'expo-font';
import axios from 'axios';
import RestFoodCard from './components/card/RestFoodCard';
import colorSchema from './config/colorSchema';
import LogoHeader from './components/header/LogoHeader';

const { width, height } = Dimensions.get('window')

const App = () => {
  const [appReady, setAppReady] = useState(false)
  const [restData, setRestData] = useState(null)
  const [foodData, setFoodData] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState('All')
  const [emptyData, setEmptyData] = useState(false)

  useEffect(() => {
    const link = window.location.href.split('/')
    if (link.length == 4 && link[3] !== '') {
      axios.get(`https://api.yemek.club/user/api/getRestaurant/${link[3]}`).then(res => {
        if (res.data?.restaurantData) {
          document.title = `${res.data.restaurantData.restaurantName} QR Menü`
          setRestData(res.data.restaurantData)
          setFoodData(res.data.foodData)
          setAppReady(true)
        }
        else {
          document.title = `Yemek.Club - 404 Not Found`
          setEmptyData(true)
          setAppReady(true)
        }
      }).catch(err => console.log(err))
    }
    else {
      document.title = `Yemek.Club - 404 Not Found`
      setEmptyData(true)
      setAppReady(true)
    }
  }, [appReady])


  const [fontsLoaded] = useFonts({
    'Gilroy-Bold': require('./assets/fonts/Gilroy-Bold.ttf'),
    'Gilroy-Medium': require('./assets/fonts/Gilroy-Medium.ttf'),
    'Gilroy-SemiBold': require('./assets/fonts/Gilroy-SemiBold.ttf'),
    'Gilroy-Regular': require('./assets/fonts/Gilroy-Regular.ttf'),
    'Gilroy-Heavy': require('./assets/fonts/Gilroy-Heavy.ttf'),
    'Gilroy-ExtraBold': require('./assets/fonts/Gilroy-ExtraBold.ttf'),
    'Gilroy-Light': require('./assets/fonts/Gilroy-Light.ttf')
  });

  if (!fontsLoaded || !appReady) {
    return null
  }

  return (
    <View style={styles.container}>
      {emptyData ? (
        <View style={styles.emptyContainer}>
          <View style={styles.logoContainer}>
            <LogoHeader />
          </View>
          <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <View>
              <Image source={require('./assets/logo.png')} style={styles.logoImage} />
            </View>
            <View style={{ marginTop: 17 }}>
              <Text style={styles.notFoundText}>404 Not Found</Text>
            </View>
            <View style={{ marginTop: 9, width: width - 25 }}>
              <Text style={styles.notFoundDescription}>Üzgünüz, istediğiniz QR menü bulunamadı. Lütfen QR menüyü doğru şekilde taradığınızdan emin olun. Yardıma ihtiyacınız varsa, lütfen müşteri hizmetlerimizle iletişime geçin.</Text>
            </View>
          </View>
        </View>
      ) : (
        <SafeAreaView style={styles.safeAreaContainer}>
          <ScrollView style={styles.scrollView}>
            <View style={{ alignItems: 'center' }}>
              <View>
                <Image source={{ uri: restData.restaurantCoverPhoto }} style={styles.coverImage} />
              </View>

              <View style={{ marginTop: 17 }}>
                <Text style={styles.restaurantName}>{restData.restaurantName}</Text>
              </View>

              <View style={{ marginTop: 7 }}>
                <Text style={styles.locationText}>{`${restData.province}, ${restData.district}`}</Text>
              </View>
            </View>

            <View style={styles.categoryContainer}>
              <View>
                <View style={styles.categoryScrollView}>
                  <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                    <TouchableOpacity onPress={() => {
                      setSelectedCategory('All')
                      foodData.map((item) => {
                        item.visible = true
                      })
                    }} style={{ backgroundColor: selectedCategory == 'All' ? colorSchema.textColor : 'rgba(211, 214, 219, 0.3)', paddingHorizontal: 12, paddingVertical: 4, borderRadius: 10, marginRight: 5 }}>
                      <Text style={{ color: selectedCategory == 'All' ? 'white' : 'black', fontFamily: 'Gilroy-Bold', fontSize: 15 }}>Hepsi</Text>
                    </TouchableOpacity>

                    {restData.subCategory.map((item, index) =>
                      <TouchableOpacity onPress={() => {
                        setSelectedCategory(item)
                        foodData.map((element) => {
                          if (element.subCategory == item) {
                            element.visible = true
                          }
                          else {
                            element.visible = false
                          }
                        })
                      }} key={index} style={{ backgroundColor: selectedCategory == item ? colorSchema.textColor : 'rgba(211, 214, 219, 0.3)', paddingHorizontal: 12, paddingVertical: 4, borderRadius: 10, marginRight: 5 }}>
                        <Text style={{ color: selectedCategory == item ? 'white' : 'black', fontFamily: 'Gilroy-Bold', fontSize: 15 }}>{item}</Text>
                      </TouchableOpacity>
                    )}
                  </ScrollView>
                </View>
              </View>
              <View style={styles.foodCardContainer}>
                {foodData.map((item, index) =>
                  item.visible ? (
                    <View key={item._id}>
                      <RestFoodCard
                        restorantOpen={true}
                        discount={item.discount}
                        discountType={item.discountType}
                        rating={item.rating}
                        comments={item.comments.length}
                        name={item.name}
                        description={item.description}
                        foodImage={item.foodImage}
                        price={item.price}
                      />
                    </View>
                  ) : (
                    <View key={index}></View>
                  )
                )}
              </View>
            </View>

            <View style={styles.eMenuContainer}>
              <View style={{ alignItems: 'center' }}>
                <Image source={require('./assets/logo.png')} style={styles.eMenuLogoImage} />
              </View>
              <Text style={styles.eMenuText}>
                Bu E-Menü <Text style={{ color: colorSchema.mainColor }}>Yemek.Club</Text> tarafından oluşturulmaktadır.
              </Text>
            </View>
          </ScrollView>
        </SafeAreaView>
      )}
    </View>
  )
}


const styles = StyleSheet.create(
  {
    container: {
      flex: 1,
    },
    emptyContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    logoContainer: {
      marginTop: 17,
    },
    logoImage: {
      width: 125,
      height: 125,
    },
    notFoundText: {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: 21,
      color: colorSchema.textColor,
    },
    notFoundDescription: {
      fontFamily: 'Gilroy-Medium',
      fontSize: 17,
      color: 'rgba(0,0,0,0.7)',
      marginTop: 9,
      width: width - 25,
      textAlign: 'center'
    },
    safeAreaContainer: {
      flex: 1,
      backgroundColor: 'white',
      paddingTop: 17,
    },
    scrollView: {
      flexGrow: 1,
    },
    coverImage: {
      width: 145,
      height: 145,
      borderRadius: 8,
    },
    restaurantName: {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: 21,
    },
    locationText: {
      fontFamily: 'Gilroy-Light',
      fontSize: 14,
      marginTop: 7,
    },
    categoryContainer: {
      marginTop: 34,
    },
    categoryScrollView: {
      marginTop: 14,
      marginLeft: 13,
    },
    categoryButton: {
      backgroundColor: 'rgba(211, 214, 219, 0.3)',
      paddingHorizontal: 12,
      paddingVertical: 4,
      borderRadius: 10,
      marginRight: 5,
    },
    selectedCategoryButton: {
      backgroundColor: colorSchema.textColor,
    },
    categoryButtonText: {
      color: 'white',
      fontFamily: 'Gilroy-Bold',
      fontSize: 15,
    },
    foodCardContainer: {
      marginLeft: 14,
      marginTop: 17,
    },
    eMenuContainer: {
      marginVertical: 72,
      width: width - 20,
    },
    eMenuLogoImage: {
      width: 100,
      height: 100,
    },
    eMenuText: {
      fontFamily: 'Gilroy-Medium',
      fontSize: 17,
      color: colorSchema.textColor,
      textAlign: 'center',
    },
  }
)

export default App