import { View, Text, ImageBackground, Dimensions } from 'react-native'
import React from 'react'
import colorSchema from '../../config/colorSchema'

const { width, height } = Dimensions.get('window')

const RestFoodCard = (props) => {
    return (
        width > 1279
            ?
            <View style={{ flexDirection: 'row', marginTop: 19 }}>
                <View>
                    <ImageBackground source={{ uri: props.foodImage }} style={{ width: 125, height: 125, borderRadius: 5 }}>
                        <View style={{ position: 'absolute', right: 4.33, top: 4.33 }}>

                        </View>
                    </ImageBackground>
                    {props.restorantOpen ? <></> : <View style={{ position: 'absolute', width: 125, height: 125, backgroundColor: 'rgba(0,0,0,0.7)' }} />}

                </View>
                <View style={{ width: width - 160, marginLeft: 15 }}>
                    <View style={{ marginBottom: 22 }}>
                        <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 16, color: colorSchema.textColor, lineHeight: 19 }}>{props.name}</Text>
                    </View>
                    <View style={{ marginBottom: 4 }}>
                        <Text numberOfLines={1} style={{ fontFamily: 'Gilroy-Regular', fontSize: 14, color: '#9E9B9B' }}>{props.description}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ flexDirection: 'row' }}>
                            <View>
                            </View>
                            <View style={{ marginLeft: 4 }}>
                            </View>
                        </View>
                        <View>
                            <View style={{ marginRight: 9 }}>
                                {props.discountType == 'fixed' ?
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ fontSize: 11, fontFamily: 'Gilroy-Medium', color: 'gray', lineHeight: 15, textDecorationLine: 'line-through' }}> {`${props.price} TL`}</Text>
                                        <Text style={{ color: props.restorantOpen ? colorSchema.greenColor : colorSchema.textColor, fontFamily: 'Gilroy-Medium', fontSize: 16 }}> {`${props.price - props.discount} TL`}</Text>
                                    </View>
                                    :
                                    props.discountType == 'percent'
                                        ?
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ fontSize: 11, fontFamily: 'Gilroy-Medium', color: 'gray', lineHeight: 15, textDecorationLine: 'line-through' }}> {`${props.price} TL`}</Text>
                                            <Text style={{ color: props.restorantOpen ? colorSchema.greenColor : colorSchema.textColor, fontFamily: 'Gilroy-Medium', fontSize: 16 }}> {`${props.price - (props.price * props.discount) / 100} TL`}</Text>

                                        </View>
                                        :
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ color: props.restorantOpen ? colorSchema.greenColor : colorSchema.textColor, fontFamily: 'Gilroy-Medium', fontSize: 16 }}> {`${props.price} TL`}</Text>
                                        </View>
                                }
                            </View>

                        </View>
                    </View>
                </View>
            </View>
            :
            <View style={{ flexDirection: 'row', marginTop: 19 }}>
                <View style={{ width: width - 160 }}>
                    <View style={{ marginBottom: 22 }}>
                        <Text style={{ fontFamily: 'Gilroy-Medium', fontSize: 16, color: colorSchema.textColor, lineHeight: 19 }}>{props.name}</Text>
                    </View>
                    <View style={{ marginBottom: 4 }}>
                        <Text style={{ fontFamily: 'Gilroy-Regular', fontSize: 14, color: '#9E9B9B' }}>{props.description}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row', marginTop: 3, marginLeft: 2 }}>
                            <Text style={{ color: props.restorantOpen ? colorSchema.greenColor : colorSchema.textColor, fontFamily: 'Gilroy-Medium', fontSize: 16 }}>{props.price} TL</Text>
                        </View>
                        <View style={{ marginRight: 30 }}>
                        </View>
                    </View>
                </View>
                <View>
                    <ImageBackground source={{ uri: props.foodImage }} style={{ width: 125, height: 125, borderRadius: 5, marginLeft: 7 }}>
                        <View style={{ position: 'absolute', right: 4.33, top: 4.33 }}>

                        </View>
                    </ImageBackground>
                    {props.restorantOpen ? <></> : <View style={{ position: 'absolute', width: 125, height: 125, backgroundColor: 'rgba(0,0,0,0.7)' }} />}

                </View>
            </View>
    )
}

export default RestFoodCard

